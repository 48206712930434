import { IBasePickerSuggestionsProps, ITag } from '@fluentui/react';

// models
import { StageCycle } from '../../../../models/Entities/Contracts/StageCycle';
import { ErrorType } from '../../../../models/HttpError';
import { Contract } from '../../../../models/Entities/Contracts/Contract';

//store 
import * as ContractCycleStore from '../../../../redux/middleware/ContractStageCycleMeddleware';

// reducer
import { AppState } from '../../../../redux/reducers';


interface IProps {
  ContractId: number;
  ContractAddendumId: number;
  onSelectedCycles: (cyles: StageCycle[]) => void;
  CyclesSelected: number[] | undefined;
  MultiselectEnable: boolean;
  noteText?: string;
}

interface ConnectedProps {
  isLoading: boolean;
  isUpdating: boolean;
  isUpdatedSuccessfully: boolean;
  isLoadingSuccessfully: boolean;
  failOnUpdating: boolean;
  failOnLoading: boolean;
  error: ErrorType | undefined;
  Cycles: StageCycle[];
}

interface DispatchProps {
  GetContractStageCyclesByAddendumId: typeof ContractCycleStore.actionCreators.GetContractStageCyclesByAddendumId;
  GetContractStageCycleById: typeof ContractCycleStore.actionCreators.GetContractStageCycleById;
}


export type Props = IProps & ConnectedProps & DispatchProps;

export const mapStateToProps = (state: AppState, ownProps: IProps) => {

  const CurrentContract = state.Contract && state.Contract.list ? state.Contract.list.find((item: Contract) => item.id === ownProps.ContractId ) : undefined;
  const CurrentAddendum = CurrentContract && CurrentContract.addendums ? CurrentContract.addendums.find((item: any) => item.id === ownProps.ContractAddendumId ) : undefined
  
  return {
  isLoading: state.ContractStageCycle?.isLoadingAll,
  isUpdating: state.ContractStageCycle?.isLoadingAll,
  isUpdatedSuccessfully: state.ContractStageCycle?.successLoadingAll,
  isLoadingSuccessfully: state.ContractStageCycle?.successLoadingAll,
  failOnUpdating: state.ContractStageCycle?.failOnLoadingAll,
  failOnLoading: state.ContractStageCycle?.failOnLoadingAll,
  error: state.ContractStageCycle?.error,
  Contract: CurrentContract,
  Cycles: CurrentAddendum?.cycles || []
  
  }
};

export const mapDispatchToProps = {
    ...ContractCycleStore.actionCreators
};


export const GetCycleOptions = (cycles: StageCycle[]): ITag[] => {
  return cycles?.map((item: StageCycle) => {
    return {
      key: item.id,
      name: item.cycleName
    };
  }) as ITag[];
};

export const listContainsTagList = (tag: ITag, tagList?: ITag[]) => {
  if (!tagList || !tagList.length || tagList.length === 0) {
    return false;
  }
  return tagList.some((compareTag) => compareTag.key === tag.key);
};

export const GetCyclesById = (
    cycleId: number,
    cycles: StageCycle[]
  ): StageCycle => {
    const Cycle = cycles.find((item: StageCycle) => item.id === cycleId) as StageCycle;
    return Cycle;
  };

export function GetSelectedCycle(
  cycleIds: number[],
  cycles: StageCycle[]
): ITag[] {
  const Cycles: ITag[] = [];

  cycleIds.forEach(async (id) => {
    const cycle = cycles.find((item: StageCycle) => item.id === id);
    if (cycle) {
    
      (cycle: StageCycle) => {
        Cycles.push({
          key: cycle.id,
          name: cycle.cycleName
        });
      }
    }

  });

  return Cycles;
}

export const getTextFromItem = (item: ITag) => item.name;

export const pickerSuggestionsProps: IBasePickerSuggestionsProps = {
  suggestionsHeaderText: 'cyclos sugeridos',
  noResultsFoundText: 'No se encontro cyclo'
};
