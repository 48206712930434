import { Contract } from "../../../../models/Entities/Contracts/Contract";
import { ContractStage } from "../../../../models/Entities/Contracts/ContractStage";
import { ErrorType } from "../../../../models/HttpError";

// stores
import * as ContractStageCycleStore from '../../../../redux/middleware/ContractStageCycleMeddleware';
import * as ContractStore from '../../../../redux/middleware/ContractMiddleware';

//State
import { AppState } from "../../../../redux/reducers";
import { StageCycle } from "../../../../models/Entities/Contracts/StageCycle";
import { ContractAddendum } from "../../../../models/Entities/Contracts/ContractAddendum";


interface IProps {
    isOpen: boolean;
    openPanel: () => void;
    dismissPanel: () => void;
    ContractId: number;
    ContractAddendumId: number;
    ContractStageCycleId: number | undefined;
}
  
interface ConnectedProps {
    // adding
    isSaving: boolean;
    isSavedSuccessfully: boolean;
    failOnSaving: boolean;


    error: ErrorType | undefined;

    TotalCycles: number;

    Contract: Contract;
    ContractStageCycle: StageCycle;
}
  
interface DispatchProps {
    GetContractStageCycleById: typeof ContractStageCycleStore.actionCreators.GetContractStageCycleById;
    AddContractStageCycle: typeof ContractStageCycleStore.actionCreators.AddContractStageCycle;
    UpdateContractStageCycle: typeof ContractStageCycleStore.actionCreators.UpdateContractStageCycle;
    GetContractById: typeof ContractStore.actionCreators.GetContractById;
}
  
export type Props = IProps & ConnectedProps & DispatchProps;
  
export const mapStateToProps = (state: AppState, ownProps: IProps) => {

    const currentContract = state.Contract?.list?.find((item: Contract) => item.id === ownProps.ContractId ) || undefined;
    const currentAddendum = currentContract?.addendums?.find((item: ContractAddendum) => item.id === ownProps.ContractAddendumId) || undefined;
    const currentTotalCycles = currentAddendum?.cycles?.length || 1;
    const currentCycle = currentAddendum?.cycles?.find((item: StageCycle) => item.id === ownProps.ContractStageCycleId) || undefined;

    return {
        isSaving: state.ContractStageCycle?.isAddingNewOne || currentCycle?.isUpdating || false,
        isSavedSuccessfully: state.ContractStageCycle?.successAddingNewOne || currentCycle?.isUpdatedSuccessfully || false,
        failOnSaving: state.ContractStageCycle?.failOnAddingNewOne || currentCycle?.failOnUpdating || false,
        error: currentCycle?.errorOnUpdating || state.ContractStageCycle?.error,

        TotalCycles: currentTotalCycles,
        
        Contract: ownProps.ContractId && state.Contract && state.Contract.list ? state.Contract.list.find((item: Contract) => item.id === ownProps.ContractId ) : undefined,
        ContractStageCycle: currentCycle,

    }
};
  
export const mapDispatchToProps = {
    ...ContractStageCycleStore.actionCreators,
    ...ContractStore.actionCreators
};