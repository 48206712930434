import {
DefaultButton,
FontWeights,
getTheme,
IconButton,
IIconProps,
IStackProps,
mergeStyleSets,
MessageBar,
MessageBarType,
Modal,
Panel,
PanelType,
PrimaryButton,
Separator,
TextField,
Toggle
} from '@fluentui/react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ProtocolContractStages } from '../../Protocols/ProtocolContractForm/ProtocolContractStages/ProtocolContractStages';
import { ErrorMessageComponent } from '../../../Error/ErrorMessageComponent';

// component functions
import * as ComponentFunctions from './StageInitialForm.Functions';
import { NewContractStage } from '../../../../models/Entities/Contracts/NewContractStage';
import { NewStageCycle } from '../../../../models/Entities/Contracts/NewStageCycle';

////i18Next
import { useTranslation, Trans } from 'react-i18next';

interface FormValidation {
success: boolean;
errors: string[];
}

export const ContractStageInitialFormComponent = (props: ComponentFunctions.Props) => {

const [submittedForm, setSubmittedForm] = useState<boolean>(false);
const [FormValidationStatus, setFormValidationStatus] = useState<
    FormValidation | undefined
>();

// entity properties
const [numberOfStages, setNumberOfStages] = useState<number>(0);
const [stageName, setStageName] = useState<string>('Visita');
const [cycleName, setCycleName] = useState<string>('Ciclo');
const [cycleAmount, setCycleAmount] = useState<number>(0);
const [currentStages, setCurrentStages] = useState<NewContractStage[]>([]);
const [currentCycles, setCurrentCycles] = useState<NewStageCycle[]>([]);

//useTranslation()
const[t] = useTranslation();

const ResetForm = () => {
    setNumberOfStages(0);
    setStageName('');
    setCycleName('');
    setCycleAmount(0);
};

useEffect(() => {   
    if (submittedForm && props.AddRangeFinalized) {
        ResetForm();
        props.dismissPanel();
    }
}, [submittedForm, props.AddRangeFinalized]);


// Events

const onChangeStageNameTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
) => {
    newValue ? setStageName(newValue) : setStageName('');
};

const onChangecycleNameTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
) => {
    newValue ? setCycleName(newValue) : setCycleName('');
};

const onChangeNumberOfStagesTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
) => {
    newValue ? setNumberOfStages(Number(newValue)) : setNumberOfStages(0);
};

const onChangeCycleAmountTextFieldValue = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
    ) => {
        newValue ? setCycleAmount(Number(newValue)) : setCycleAmount(0);
    }

const HandleSaveForm = async () => {
    setSubmittedForm(true);
    if (ValidateForm()) {
      props.AddContractStageCycleRange(props.ContractId, props.AddendumId, currentCycles as NewStageCycle[]);
      props.AddContractStageRange(props.ContractId, props.AddendumId, currentStages as NewContractStage[]);
    }
};

const HandleCancelForm = () => {
    ResetForm();
    props.dismissPanel();
};

// footer
const onRenderFooterContent = () => {
    return (
    <div className={contentStyles.footer}>
        <DefaultButton onClick={HandleCancelForm} className="OtherOption">
            <Trans i18nKey="stageInitialForm.footer.cancelButton" />
        </DefaultButton>
        <PrimaryButton
        onClick={HandleSaveForm}
        styles={buttonStyles}
        className="ConfirmAction"
        >
            <Trans i18nKey="stageInitialForm.footer.saveButton" />
        </PrimaryButton>
    </div>
    );
};

const formFields = (
    <div {...columnProps}>
        <TextField
            label={t('stageInitialForm.props.visitName')} 
            name="txt_stage_name"
            required
            type="text"
            autoComplete="off"
            onChange={onChangeStageNameTextFieldValue}
            errorMessage={
            (submittedForm === true && stageName === undefined) ||
            (submittedForm === true &&
                stageName !== undefined &&
                stageName.length === 0)
                ?  t('stageInitialForm.props.requiredField')
                : undefined
            }
            value={stageName || ''}
        />
        <TextField
            label={t('stageInitialForm.props.visitNumber')} 
            name="txt_number_of_stages"
            required
            type="number"
            autoComplete="off"
            onChange={onChangeNumberOfStagesTextFieldValue}
            errorMessage={
            (submittedForm === true && numberOfStages === undefined) ||
            (submittedForm === true &&
                numberOfStages !== undefined &&
                numberOfStages === 0)
                ? t('stageInitialForm.props.requiredField') 
                : undefined
            }
            value={numberOfStages.toString() || ''}
        />
        <TextField
            label={t('stageInitialForm.props.cycleName')} 
            name="txt_cycle_name"
            type="text"
            autoComplete="off"
            onChange={onChangecycleNameTextFieldValue}
            errorMessage={
            (submittedForm === true && cycleName === undefined) ||
            (submittedForm === true &&
                cycleName !== undefined &&
                cycleName.length === 0)
                ? t('stageInitialForm.props.requiredField') 
                : undefined
            }
            value={cycleName || ''}
        />
        <TextField
            label={t('stageInitialForm.props.cycleNumber')}            
            name="txt_number_of_cycles"
            type="number"
            autoComplete="off"
            onChange={onChangeCycleAmountTextFieldValue}
            errorMessage={undefined}
            value={cycleAmount.toString() || ''}
        />
        <Separator />
        <ProtocolContractStages 
            onChangeStages={(stages: NewContractStage[]) => { console.log('Visitas Actuales', stages); setCurrentStages(stages)}}
            onChangeCycles={(cycles: NewStageCycle[]) => setCurrentCycles(cycles)}
            currentStages={[]}
            currentCycles={[]} 
            stageName={stageName} 
            stageAmount={numberOfStages} 
            CycleAmount={cycleAmount} 
            CycleName={cycleName} 
        />
    </div>
    
);

// form validation

const ValidateForm = (): boolean => {
    let validationResult: boolean = true;

    let FormValidation: FormValidation = { success: true, errors: [] };
    setFormValidationStatus(FormValidation);

    if (stageName === undefined || stageName.length === 0) {
    FormValidation.errors.push(t('stageInitialForm.props.requiredVisitName'));
    validationResult = false;
    };

    if (numberOfStages === undefined || numberOfStages === 0) {
        FormValidation.errors.push(t('stageInitialForm.props.requiredVisitNumber'));
        validationResult = false;
    };

    FormValidation.success = validationResult;
    setFormValidationStatus(FormValidation);
    return validationResult;
};

return (
    <Panel
    isOpen={props.isOpen}
    type={PanelType.medium}
    headerText={t('stageInitialForm.panel.visitInformation')}
    onDismiss={props.dismissPanel}
    closeButtonAriaLabel="Close"
    onRenderFooter={onRenderFooterContent}
    isFooterAtBottom={true}
    >
    {/* <div className={contentStyles.header}>
        <span>Información del Proveedor</span>
        <IconButton
        styles={iconButtonStyles}
        iconProps={cancelIcon}
        ariaLabel="Close popup modal"
        onClick={props.dismissPanel}
        />
    </div> */}
    {submittedForm && !FormValidationStatus?.success && (
        <MessageBar messageBarType={MessageBarType.error} isMultiline={true}>
            {t('stageInitialForm.panel.reviewInformation')}
        <ul>
            {FormValidationStatus?.errors.map(
            (Error: string, index: number) => (
                <li key={index}>{Error}</li>
            )
            )}
        </ul>
        </MessageBar>
    )}
    <div className={contentStyles.body}>
        {props.failOnAdding &&
        props.error && (
            <ErrorMessageComponent Error={props.error} />
        )}
        {formFields}
    </div>
    </Panel>
);
};

export default connect(
ComponentFunctions.mapStateToProps,
ComponentFunctions.mapDispatchToProps
)(ContractStageInitialFormComponent as any);

const buttonStyles = { root: { marginRight: 8, class: 'ConfirmAction' } };

const columnProps: Partial<IStackProps> = {
tokens: { childrenGap: 15 }
// styles: { root: { width: 150 } }
};

const theme = getTheme();
const contentStyles = mergeStyleSets({
container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    borderRadius: 10
},
header: [
    theme.fonts.mediumPlus,
    {
    flex: '1 1 auto',
    color: theme.palette.neutralPrimary,
    display: 'flex',
    alignItems: 'center',
    fontWeight: FontWeights.semibold,
    padding: '0px 12px 12px 12px',
    backgroundColor: 'lightgray',
    borderRadius: '10px 10px 0 0'
    }
],
body: {
    flex: '4 4 auto',
    overflowY: 'auto',
    selectors: {
    'p:first-child': { marginTop: 0 },
    'p:last-child': { marginBottom: 0 }
    },
    padding: '0 1em 0 0',
    maxHeight: '85vh'
},
footer: {
    //flex: '4 4 auto',
    padding: '0 24px 24px 24px'
}
});

const cancelIcon: IIconProps = { iconName: 'Cancel' };

const iconButtonStyles = {
root: {
    color: theme.palette.neutralPrimary,
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px'
},
rootHovered: {
    color: theme.palette.neutralDark
}
};
