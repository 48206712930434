import { useState } from 'react';
import { Label, IconButton, Text, FontSizes, Separator } from '@fluentui/react';
import { useTranslation } from 'react-i18next';

// Functions 
import { connect } from 'react-redux';
import * as ComponentFunctions from './SettlementDetailItem.Functions';

// Components
import SettlementDetailForm from '../SettlementDetailForm/SettlementDetailForm.Component';
import SettlementDetailFormReview from '../SettlementDetailsFormReview/SettlementDetailsFormReview.Component';

// Styles
import './SettlementDetailItem.Styles.css';
import MOMENT from 'react-moment';
import { DetailType } from '../../../models/Entities/SettlementDetails/NewSettlementDetail';
import { MessageBar, MessageBarBody, MessageBarTitle } from '@fluentui/react-components';
import SettlementDetailValuesComponent from '../SettlementDetailValues/SettlementDetailValues.Component';


const SettlementDetailItem = (props: ComponentFunctions.Props) => {

    //useTranslation();
    const [t] = useTranslation();

    // form control 

    const [showDetailForm, setShowDetailForm] = useState<boolean>(false);
    const [showDetailsFormReview, setShowDetailsFormReview] = useState<boolean>(false);

    const handleClosePanel = () => {
        console.log('cerrando panel');
        setShowDetailForm(false);
    };

    const handleClosePanelReview = () => {
        setShowDetailsFormReview(false);
    };

    // actions

    const HandleChangeExclusionStatus = () => {
        props.CurrentDetail && props.ChangeExcludeStatus(props.CurrentDetail.id, !props.CurrentDetail.isExcluded);
    }

    const HandleChangeOkStatus = () => {
        props.CurrentDetail && props.MarkOk(props.CurrentDetail.id, !props.CurrentDetail.markOk);
    }

    const HandleEditConcept = () => {
        setShowDetailForm(true);
    };

    const HandleShowReviewForm = () => {
        setShowDetailsFormReview(true);
    }

    const Options = (
        <>
            <IconButton
                iconProps={ComponentFunctions.EditIcon}
                title={t('settlementDetailList.buttons.editButton')}
                onClick={HandleEditConcept}
            />
            {!props.CurrentDetail?.isExcluded && <IconButton
                iconProps={ComponentFunctions.Exclude}
                title={t('settlementDetailList.buttons.excludeButton')}
                style={{ color: 'red' }}
                onClick={HandleChangeExclusionStatus}
            />}
            {props.CurrentDetail?.isExcluded && <IconButton
                iconProps={ComponentFunctions.Include}
                title={t('settlementDetailList.buttons.includeButton')}
                style={{ color: 'darkgreen' }}
                onClick={HandleChangeExclusionStatus}
            />}
            {!props.CurrentDetail?.isExcluded && <IconButton
                iconProps={ComponentFunctions.VerifycationOk}
                title="Verificado"
                style={{ color: 'darkgreen' }}
                onClick={HandleChangeOkStatus}
            />}
            {<IconButton
                iconProps={ComponentFunctions.ItemObservedIcon}
                title="Observar"
                style={{ color: 'gray' }}
                onClick={HandleShowReviewForm}
            />}
        </>
    )

    const Forms = props.CurrentDetail && (
        <>
            {showDetailForm && <SettlementDetailForm
                isOpen={showDetailForm}
                dismissPanel={handleClosePanel}
                openPanel={() => undefined}
                SettlementId={props.SettlementId}
                SettlementResumeId={props.CurrentDetail.settlementResumeId}
                SettlementDetailId={props.CurrentDetail.id}
            />}
            <SettlementDetailFormReview
                isOpen={showDetailsFormReview}
                dismissPanel={handleClosePanelReview}
                openPanel={() => undefined}
                SettlementId={props.SettlementId}
                SettlementDetailId={props.CurrentDetail.id}
            />
        </>
    )


    return (
        <>
            <div className={'DetailItemContainer ' + ComponentFunctions.GetStatusClass(props.CurrentDetail)} >
                <div className='DetailInfo'>
                    <div>
                        {props.CurrentDetail?.contractStageName &&
                            <Text style={{ fontSize: FontSizes.small }}>{props.CurrentDetail?.contractStageName}
                                {(props.CurrentDetail?.patientNumberInProtocol && <span> - {props.CurrentDetail?.patientNumberInProtocol}</span>)}
                                {props.CurrentDetail?.notEnoughInfo && <span className='DetailSatusBagded red'>No hay suficiente información para calcular el valor</span>}
                            </Text>}
                        <Label style={{ fontSize: FontSizes.mediumPlus }}>{props.CurrentDetail?.description}
                            {(props.CurrentDetail?.isExcluded ||
                                props.CurrentDetail?.markOk ||
                                props.CurrentDetail?.markForReview) &&
                                <span className={'DetailSatusBagded ' + ComponentFunctions.GetStatusClass(props.CurrentDetail)}>{t(ComponentFunctions.GetStatusText(props.CurrentDetail))}</span>}</Label>

                    </div>
                    <Text>{props.CurrentDetail?.value.toLocaleString('us-US', { style: 'currency', currency: props.CurrentDetail?.currency?.toUpperCase() })}</Text>
                </div>
                <div className='AditionalInfomationContainer'>
                    <div className='InformacionContainer'>
                        <div >
                            {props.CurrentDetail?.haveWarning && <>
                                <MessageBar key={'detailsWarning' + props.CurrentDetail?.id} intent={'warning'}>
                                    <MessageBarBody>
                                        <MessageBarTitle>Atención</MessageBarTitle>
                                        {props.CurrentDetail.warningDescription}
                                    </MessageBarBody>
                                </MessageBar>
                            </>}
                            <div className='rowInformation'>
                                <div>
                                    {props.CurrentDetail?.dueDate && <>
                                        <small>Fecha de realización</small>
                                        <Label>
                                            <MOMENT format="DD/MM/YYYY">
                                                {props.CurrentDetail?.dueDate}
                                            </MOMENT>
                                        </Label>
                                    </>}
                                </div>
                                <div>
                                    <Text style={{ fontSize: FontSizes.small }}>Origen del concepto</Text>
                                    <Label>{props.CurrentDetail?.type === DetailType.Manual ? "Manual" : "Automático"}</Label>
                                </div>
                            </div>
                            {props.CurrentDetail?.comments && <>
                                <small>Comentarios</small>
                                <Label>{props.CurrentDetail.comments}</Label>
                            </>}

                            {(props.CurrentDetail?.conceptValue || []).length > 0 ? <>
                                <Separator />
                                <Label>Fees y valores agregados</Label>
                                <SettlementDetailValuesComponent SettlementId={props.SettlementId} SettlementDetailId={props.SettlementDetailId} />
                            </> : <></>}
                        </div>
                        <div>
                            <small>Valor por item</small>
                            <Label>{props.CurrentDetail?.unitValue.toLocaleString('es-AR', { style: 'currency', currency: props.CurrentDetail?.currency?.toUpperCase() })}</Label>
                            <small>Overhead Incluido en el valor por contrato</small>
                            <Label>({props.CurrentDetail?.overheadPercentage + '%'})&nbsp;{props.CurrentDetail?.overheadValue.toLocaleString('us-US', { style: 'currency', currency: props.CurrentDetail?.currency?.toUpperCase() })}</Label>
                            <small>Valor final sin overhead</small>
                            <Label>{props.CurrentDetail?.value.toLocaleString('es-AR', { style: 'currency', currency: props.CurrentDetail?.currency?.toUpperCase() })}</Label>
                            {props.AditionalValue > 0 && <><Separator />
                            <small>Valor más adicionales</small>
                            <Label>{props.CurrentDetail?.value.toLocaleString('es-AR', { style: 'currency', currency: props.CurrentDetail?.currency?.toUpperCase() })} + {props.AditionalValue?.toLocaleString('es-AR', { style: 'currency', currency: props.CurrentDetail?.currency?.toUpperCase() })} = {(props.AditionalValue + (props.CurrentDetail?.value || 0))?.toLocaleString('es-AR', { style: 'currency', currency: props.CurrentDetail?.currency?.toUpperCase() })} </Label></>}
                            <Separator />
                            <small>WithHolding</small>
                            <Label>({props.CurrentDetail?.withHoldPercentage + '%'})&nbsp;{props.CurrentDetail?.withHoldAmount.toLocaleString('us-US', { style: 'currency', currency: props.CurrentDetail?.currency?.toUpperCase() })}</Label>
                            <small>Costo</small>
                            <Label>{props.CurrentDetail?.costValue?.toLocaleString('us-US', { style: 'currency', currency: props.CurrentDetail?.currency?.toUpperCase() })}</Label>
                        </div>
                    </div>
                    <div className='DetailItemActions'>
                        {Options}
                    </div>
                </div>

            </div>
            {Forms}
        </>
    );
};

export default connect(
    ComponentFunctions.mapStateToProps,
    ComponentFunctions.mapDispatchToProps
)(SettlementDetailItem as any);