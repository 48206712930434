import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Contract } from '../../../models/Entities/Contracts/Contract';
import { DefaultButton, FontIcon, FontSizes, FontWeights, Icon, IconButton, Label, MessageBarType, PrimaryButton, SearchBox, Separator, Spinner, Text } from '@fluentui/react';
import { MessageComponent } from '../../../commons/component/MessageComponent';
import { connect } from 'react-redux';
import { useBoolean } from '@fluentui/react-hooks';
import ProtocolContractFormComponent from '../Protocols/ProtocolContractForm/ProtocolContractFormComponent';
import { ConfirmationButtonComponent } from '../../../commons/component/ConfirmationButton/ConfirmationButtonComponent';


////i18Next
import { useTranslation, Trans } from 'react-i18next';

// functions
import * as ComponentFunctions from './ContractList.Functions';

// Styles
import './ContractList.Styles.css';
import moment from 'moment';
import ContractCloneFormComponent from '../ContractCloneForm/ContractCloneForm.Component';


const ContractListComponent = (props: ComponentFunctions.Props) => {

  const [selectedContract, setSelectedContract] = useState<Contract | undefined>(undefined);

  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] =
    useBoolean(false);

  const [isCloneFormOpen, { setTrue: openCloneFormPanel, setFalse: dismissCloneFormPanel }] =
    useBoolean(false);



  const [searchText, setSearchText] = useState('');

  const navigate = useNavigate();

  const handleClosePanel = () => {
    setSelectedContract(undefined);
    dismissPanel();
  };

  const handleCloseCloneFormPanel = () => {
    setSelectedContract(undefined);
    dismissCloneFormPanel();
  }

  //useTranslation()
  const [t] = useTranslation();

  useEffect(() => {
    props.GetAllContracts();
  }, []);

  //events
  const onViewContractEvent = (contract: Contract) => {
    setSelectedContract(contract);
    openPanel();
  };

  const onSelectedContract = (contract: Contract) => {
    if (props.SelectedEvent)
      props.SelectedEvent(contract);
  }

  const onRequestCloneContract = (contract: Contract) => {
    setSelectedContract(contract);
    openCloneFormPanel();
  }

  const onRequestDeleteContract = (contract: Contract) => {
    props.DeleteContract(contract.id);
  }

  const onSearch = (newValue: string) => {
    setSearchText(newValue);
  };


  //#region Subcontent definition

  // component searchbox
  const Searchbox = <SearchBox
    placeholder={t('contractList.searchBox.search')}
    //onChange={OnSearch}
    underlined={false}
    onChange={(
      event?: React.ChangeEvent<HTMLInputElement> | undefined,
      newValue?: string | undefined
    ) => (newValue ? onSearch(newValue) : undefined)}
    onClear={() => onSearch('')}
  />

  // Principal error message
  const PrincipalErrorMessage = props.failOnLoading && (
    <MessageComponent
      message={
        t('contractList.errorMessage.contractError')
      }
      subMessage={props.error?.ErrorMessage || ''}
      type={MessageBarType.error}
      itemList={props.error?.Errors || []}
    />
  );

  // component Controls
  const Controls = props.ShowControls && (
    <>
      <div className="Controls">
        <div className="AddButton">
          <PrimaryButton
            text={t('contractList.componentControls.addContract')}
            iconProps={{ iconName: 'Add' }}
            onClick={openPanel}
          />
        </div>
      </div>
      <Separator />
    </>
  );

  // Loading
  const Loading = props.isLoading && (
    <div>
      <Spinner
        label={t('contractList.loading.loadingContracts')}
        ariaLive="assertive"
        labelPosition="top"
      />
    </div>
  );

  // Table of content
  const TableOfContent = props.isLoadingSuccessfully && props.Contracts.length > 0 && (
    <table className="Table">
      <thead>
        <tr>
          <th className='ContractAssociationHeader'></th>
          {/* <th style={{ width: 1 }}></th> */}
          <th style={{ maxWidth: '5em' }}>{t('contractList.contentTable.contract')}</th>
          <th>{t('contractList.contentTable.options')}</th>
        </tr>
      </thead>
      <tbody>
        {props.Contracts.length > 0 ? (props.FilteredContractIds ? props.Contracts.filter((contract: Contract) => props.FilteredContractIds?.includes(contract.id)) : props.Contracts).map((item: Contract) =>
          item.contractNumber.toLowerCase().includes(searchText.toLowerCase()) ? (
            <tr
              key={item.id}
              className={"TableBodyRow"}
            >
              <td className='LinkIcon'>
                {(item.practitioners?.length > 0 || item.protocol?.length > 0) && <div>
                  {item.practitioners?.length > 0 && <FontIcon iconName='UserFollowed' style={{ fontSize: FontSizes.size24 }} />}
                  {item.protocol?.length > 0 && <FontIcon iconName='TestUserSolid' style={{ fontSize: FontSizes.size20 }} />}
                </div>}
              </td>
              {/* <td>
                <FontIcon iconName='EntitlementRedemption' style={{ marginTop: 8, fontSize: FontSizes.size24 }} />
              </td> */}
              <td className="primary" onClick={() => onSelectedContract(item)}>
                <Label style={{ fontWeight: FontWeights.bold }}><FontIcon iconName={item.isLocked ? 'Lock' : 'Unlock'} style={{
                  color: 'white',
                  paddingTop: '5',
                  fontWeight: FontWeights.bold,
                  fontSize: FontSizes.size12,
                  padding: '0.3em .6em',
                  backgroundColor: 'green',
                  marginRight: '.2em',
                  borderRadius: '2em'
                }} />&nbsp;{item.contractNumber.toUpperCase()}<span style={{ fontWeight: FontWeights.regular }}>&nbsp;|&nbsp;{ComponentFunctions.GetContractStatusDescripcion(item.contractStatus)}</span></Label>
                {item.contractDescription && <p>
                  <Text>{item.contractDescription}</Text>
                </p>}
                <Label style={{ display: 'flex' }}><FontIcon iconName='Calendar' style={{ marginTop: 3, fontSize: FontSizes.medium }} />&nbsp;{t('contractList.contentTable.validity')}&nbsp;<strong>{item.contractStartDate ? moment(item.contractStartDate).format('DD/MM/YYYY') : t('contractList.contractStartDate.notDefined')}</strong>&nbsp;a&nbsp;<strong>{item.contractEndDate ? moment(item.contractEndDate).format('DD/MM/YYYY') : t('contractList.contractEndDate.notDefined')}</strong></Label>
              </td>
              <td>
                <div className='ContractOptions'>
                  <div>
                    <IconButton
                      iconProps={{ iconName: 'Edit' }}
                      title={t('contractList.contentTable.information')}
                      ariaLabel="Información"
                      onClick={() => onViewContractEvent(item)}
                    />
                    <IconButton
                      iconProps={{ iconName: 'ChromeRestore' }}
                      title={t('contractList.contentTable.information')}
                      ariaLabel="Información"
                      onClick={() => onRequestCloneContract(item)}
                    />
                    <div className='danger'>
                      <ConfirmationButtonComponent
                        ButtonId={"deleteContract" + item.id}
                        ButtonIconName={"Delete"}
                        ButtonText={""}
                        ButtonCssClass={""}
                        ConfirmationButtonText={t('contractList.contentTable.confirmationButtonDelete.confirm')}
                        ConfirmationButtonCssClass={"deleteButton"}
                        CancelButtonText={t('contractList.contentTable.confirmationButtonDelete.cancel')}
                        CancelButtonCssClass={""}
                        Title={t('contractList.contentTable.confirmationButtonDelete.deleteContract')}
                        Description={""}
                        ElementTextHeader={t('contractList.contentTable.confirmationButtonDelete.deleteContractInformation') + item.contractNumber}
                        ElementTextInformation={t('contractList.contentTable.confirmationButtonDelete.operationInfo')}
                        onConfirmation={() => onRequestDeleteContract(item)}
                        onCancel={function (): void {
                          throw new Error('Function not implemented.');
                        }}
                        isOperationExecuting={
                          props.isDeleting
                        }
                        isOperationExecutedWithError={
                          props.failOnDeleting
                        }
                        OperationError={props.error}
                      />
                    </div>
                  </div>

                </div>
              </td>
            </tr>
          ) : null
        ) : <tr><td>{t('contractList.contentTable.noData')}</td></tr>}
      </tbody>
    </table>
  );

  const NoRegistries = (props.Contracts?.length === 0 || props.Contracts?.length === undefined) && !props.failOnLoading && !props.isLoading && (
    <div className="NoDataSection">
      <img
        src={require('../../../assets/img/empty_state.gif')}
        alt="Ichtys"
        style={{ width: '10em', height: '10em', marginBottom: '1em' }}
      ></img>
      <Label>{t('contentTable.noRegistries.registeredContracts')}</Label>
      <DefaultButton
        text={t('contentTable.noRegistries.uploadContract')}
        iconProps={{ iconName: 'Add' }}
        style={{ marginTop: '1em' }}
        onClick={openPanel}
      />
    </div>
  );

  const Forms = (
    <>
      <ProtocolContractFormComponent
        isOpen={isOpen}
        Contract={selectedContract}
        dismissPanel={handleClosePanel}
        openPanel={openPanel}
        mode=''
        RefererId={undefined}
      />
      {selectedContract && <ContractCloneFormComponent
        isOpen={isCloneFormOpen}
        ContractId={selectedContract?.id}
        dismissPanel={handleCloseCloneFormPanel}
        openPanel={openCloneFormPanel}
      />}
    </>);

  //#endregion

  return (
    <>
      {Controls}
      {PrincipalErrorMessage}
      {TableOfContent}
      {Loading}
      {NoRegistries}
      {Forms}
    </>
  );
};

export default connect(
  ComponentFunctions.mapStateToProps,
  ComponentFunctions.mapDispatchToProps
)(ContractListComponent as any);
