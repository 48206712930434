import { Contract } from "../../../../models/Entities/Contracts/Contract";
import { StageCycle } from "../../../../models/Entities/Contracts/StageCycle";

import { ErrorType } from "../../../../models/HttpError";

// stores
import * as ContractCycleStore from '../../../../redux/middleware/ContractStageCycleMeddleware';

//State
import { AppState } from "../../../../redux/reducers";


interface IProps {
    ContractId: number;
    ContractAddendumId: number;
}
  
  interface ConnectedProps {
    isLoading: boolean;
    isLoadingSuccessfully: boolean;
    failOnLoading: boolean;

    isDeleting: boolean;
    isDeletedSuccessfully: boolean;
    failOnDeleting: boolean;

    error: ErrorType | undefined;

    Contract: Contract | undefined;
    Cycles: StageCycle[];
  }
  
  interface DispatchProps {
    GetAllContractsStageCycles: typeof ContractCycleStore.actionCreators.GetAllContractsStageCycles;
    GetContractStageCyclesByAddendumId: typeof ContractCycleStore.actionCreators.GetContractStageCyclesByAddendumId;
    GetContractStageCycleById: typeof ContractCycleStore.actionCreators.GetContractStageCycleById;
    DeleteContractStageCycle: typeof ContractCycleStore.actionCreators.DeleteContractStageCycle;
  }

  
  export type Props = IProps & ConnectedProps & DispatchProps;
  
  export const mapStateToProps = (state: AppState, ownProps: IProps) => {

    const CurrentContract = state.Contract && state.Contract.list ? state.Contract.list.find((item: Contract) => item.id === ownProps.ContractId ) : undefined;
    const CurrentAddendum = CurrentContract && CurrentContract.addendums ? CurrentContract.addendums.find((item: any) => item.id === ownProps.ContractAddendumId ) : undefined;
    
    return {
      // loading
      isLoading: state.ContractStageCycle?.isLoadingAll,
      isLoadingSuccessfully: state.ContractStageCycle?.successLoadingAll,
      failOnLoading: state.ContractStageCycle?.failOnLoadingAll,
      
      // deleting
      isDeleting: state.ContractStageCycle?.isDeletingOne,
      isDeletedSuccessfully: state.ContractStageCycle?.successDeletingOne,
      failOnDeleting: state.ContractStageCycle?.failOnDeletingOne,

      error: state.ContractStageCycle?.error,
      
      Contract: ownProps.ContractId && state.Contract && state.Contract.list ? state.Contract.list.find((item: Contract) => item.id === ownProps.ContractId ) : undefined,
      Cycles: CurrentAddendum?.cycles?.sort(
        (a: StageCycle, b: StageCycle) => a.cycleName.localeCompare(b.cycleName)
      ) || []
    }
  };
  
  export const mapDispatchToProps = {
    ...ContractCycleStore.actionCreators
  };