import { Label, Pivot, PivotItem, Separator } from "@fluentui/react";
import { connect } from "react-redux";
import { withRouter } from "../../../commons/component/withRouterCustom";
import { useEffect, useState } from "react";

// styles
import './ContractDetail.Styles.css';

// functions
import * as ComponentFunctions from './ContractDetails.Functions';

//i18Next
import { useTranslation, Trans } from 'react-i18next';

// components
import CommissionAgentAssociatedComponent from "../../../components/Contracts/CommissionAgentAssociated/CommissionAgentAssociated.Component";
import AddendumsComponent from "../../../components/Contracts/Addendums/Addendums.List/Addendums.Components";
import ProtocolParactitionersListComponent from "../../../components/Protocols/Practitioners/PractitionerList/ProtocolPractitiones.List.Component";
import FeeDivisionContractComponent from "../../../components/Contracts/FeeDivision/FeeDivisionContract.Component";
import StageListComponent from "../../../components/Contracts/Stages/StageList/StageList.Component";
import CycleListComponent from "../../../components/Contracts/Cycles/CycleList/CycleList.Component";
import ConceptListComponent from "../../../components/Contracts/Concept/ConceptList/ConceptList.Component";
import BranchListComponent from "../../../components/Contracts/Branchs/BranchList/BranchList.Component";
import SettlementListComponent from "../../../components/Settlements/SettlementList/SettlementList.Component";
import ContractAssociationsComponent from "../../../components/Contracts/ContractAssociations/ContractAssociations.Component";
import { PageHeader } from "../../../commons/component/PageHeader/PageHeader";
import ContractHeaderComponent from "../../../components/Contracts/Header/ContractHeader.Component";

// models
import { ContractAddendum } from "../../../models/Entities/Contracts/ContractAddendum";

const ContractDetailPage = (props: ComponentFunctions.Props) => {

    // state
    const [SelectedAddendum, setSelectedAddendum] = useState<ContractAddendum | undefined>(undefined);

    //useTranslation()
    const [t] = useTranslation();

    useEffect(() => {
        props.GetContractById(props.ContractId);
        if (props.Contract) {
            props.GetContractAddendumsByContractId(props.ContractId);
        }
    }, [props.ContractId]);

    // useEffect(() => {
    //     if (props.DefaultContractAddendum) {
    //         setSelectedAddendum(props.DefaultContractAddendum);
    //     }
    // }, [props.DefaultContractAddendum]);

    // useEffect(() => {
    //     if(SelectedAddendum) {
    //         props.GetAllContractsConcept(props.ContractId);
    //         props.GetAllContractCommissionConcepts(props.ContractId);
    //         props.GetAllContractsStage(props.ContractId, SelectedAddendum!.id);
    //     }
    // }, [SelectedAddendum]);

    return (
        <>
            <PageHeader title={t('contractDetailPage.contractDetailtitle')} />
            <ContractHeaderComponent ContractId={props.ContractId} />
            <div className="Section">
                <AddendumsComponent ContractId={props.ContractId} OnAddendumSelected={setSelectedAddendum} />
            </div>
            <div className="ContractPageContent">
                <div className="Section">
                    <Pivot>
                        <PivotItem headerText={t('contractDetailPage.contractDetailVisits')}>
                            <div className="VisitasSection">
                                <div>
                                    {SelectedAddendum && <StageListComponent ContractId={props.ContractId} ContractAddendumId={SelectedAddendum?.id} />}
                                </div>
                                <div>
                                    <Label>{t('contractDetailPage.contractDetailCycles')}</Label>
                                    {SelectedAddendum && <CycleListComponent ContractId={props.ContractId} ContractAddendumId={SelectedAddendum?.id} />}
                                    <Separator />
                                    {/* <Label>{t('contractDetailPage.contractDetailBranchs')}</Label>
                                    <BranchListComponent ContractId={props.ContractId} /> */}
                                </div>
                            </div>
                        </PivotItem>
                        <PivotItem headerText={t('contractDetailPage.contractDetailItem.conceptsSettled')}>
                            {SelectedAddendum && <ConceptListComponent
                                ContractId={props.ContractId}
                                ContractAddendumId={SelectedAddendum?.id}
                            />}
                        </PivotItem>
                        <PivotItem headerText={t('contractDetailPage.contractDetailItem.liquidations')}>
                            <SettlementListComponent ContractId={props.ContractId} />
                        </PivotItem>
                        {props.Protocol && props.Contract && <PivotItem headerText={t('contractDetailPage.contractDetailItem.medicalEquipment')}>
                            <>
                                <h5>{t('contractDetailPage.contractDetailFeeDivision')}</h5>
                                <FeeDivisionContractComponent ContractId={props.Contract.id} />
                                <Separator />
                                <ProtocolParactitionersListComponent showParticipation={(props.feeDivision || 0) === 0} ContractId={props.ContractId} ProtocolId={props.Protocol?.id} />
                                {/* <Pivot>
                                    <PivotItem headerText={t('contractDetailPage.contractDetailItem.financialParticipation')}>
                                        <ProtocolConceptMultiSelectorComponent
                                            concepts={props.ContractConcepts}
                                            selectedConceptIds={props.ContractCommissionConcepts?.map((contractCommissionConcept) => contractCommissionConcept.conceptId) || []}
                                            showPercentage={true}
                                            onAddConcept={AddConcept}
                                            onDeleteConcept={DeleteConcept}
                                        //onChangeParticipationPercentage={ChangeParticipationPercentage}
                                        //onCostParticipationActiveChange={ChangeCostParticipationActive}
                                        //onCostParticipationPercentageChange={ChangeCostParticipationPercentage}
                                        />
                                    </PivotItem>
                                    <PivotItem headerText={t('contractDetailPage.contractDetailItem.teamMembers')}>
                                       
                                    </PivotItem>
                                </Pivot> */}
                            </>
                        </PivotItem>}
                    </Pivot>

                </div>
                <div className="Section">
                    <ContractAssociationsComponent ContractId={props.ContractId} />
                    <CommissionAgentAssociatedComponent ContractId={props.ContractId} />
                </div>
            </div>

        </>);
}

export default withRouter(connect(
    ComponentFunctions.mapStateToProps,
    ComponentFunctions.mapDispatchToProps
)(ContractDetailPage as any));