import { AppState } from '../../../redux/reducers';
import { Contract } from '../../../models/Entities/Contracts/Contract';
import { ErrorType } from '../../../models/HttpError';
import { Practitioner } from '../../../models/Entities/Practitioners/Practitioner';
import { Protocol } from '../../../models/Entities/Protocols/Protocol';

//stores 
import * as ContractsStore from '../../../redux/middleware/ContractMiddleware'
import * as ContractConceptStore from '../../../redux/middleware/ContractConceptMiddleware'
import * as ProtocolsStore from '../../../redux/middleware/MedicalProtocolMiddleware'
import * as PractitionersStore from '../../../redux/middleware/PractitionerMiddleware'
import * as ContractCommissionConceptStore from '../../../redux/middleware/ContractCommissionConceptMiddleware'
import * as ContractStageStore from '../../../redux/middleware/ContractStageMiddleware';
import * as ContractAddendumStore from '../../../redux/middleware/ContractAddendum.Middleware';

// models
import { ContractCommissionConcept } from '../../../models/Entities/Contracts/ContractCommissionConcept';
import { ContractConcept } from '../../../models/Entities/Contracts/ContractConcept';
import { ContractAddendum } from '../../../models/Entities/Contracts/ContractAddendum';


//#region Props

interface IProps {}

interface ConnectedProps {
  ContractId: number;
  Contract: Contract | undefined;
  ContractConcepts: ContractConcept[];
  Practitioner: Practitioner | undefined;
  ProtocolId: number | undefined;
  Protocol: Protocol | undefined;
  feeDivision: number | undefined;
  ContractCommissionConcepts: ContractCommissionConcept[];
  Error: ErrorType | undefined;
  DefaultContractAddendum: ContractAddendum | undefined;
}

interface MedicalServicesDispatchProps {
    GetContractById: typeof ContractsStore.actionCreators.GetContractById;
    CreateContractCommissionConcept : typeof ContractCommissionConceptStore.actionCreators.CreateContractCommissionConcept;
    UpdateContractCommissionConcept : typeof ContractCommissionConceptStore.actionCreators.UpdateContractCommissionConcept;
    DeleteContractCommissionConcept : typeof ContractCommissionConceptStore.actionCreators.DeleteContractCommissionConcept;
    GetAllContractsConcept : typeof ContractConceptStore.actionCreators.GetAllContractsConcept;
    GetAllContractCommissionConcepts : typeof ContractCommissionConceptStore.actionCreators.GetAllContractCommissionConcepts;
    GetAllContractsStage : typeof ContractStageStore.actionCreators.GetAllContractsStage;
    GetContractAddendumsByContractId : typeof ContractAddendumStore.actionCreators.GetContractAddendumsByContractId;
}

export type Props = IProps & ConnectedProps & MedicalServicesDispatchProps;

export const mapStateToProps = (state: AppState, ownProps: any) => {

    const ContractId = Number(ownProps.match.params.ContractId);

    const ContractProtocolAssociated : number | undefined = state.Contract?.list?.find((contract: Contract) => contract.id === ContractId)?.protocol[0]?.protocolId;
    const ContractPractitionerAssociated : string | undefined = state.Contract?.list?.find((contract: Contract) => contract.id === ContractId)?.practitioners[0]?.practitionerId;
    const ContractCommissionConcepts : ContractCommissionConcept[] = state.Contract?.list?.find((contract: Contract) => contract.id === ContractId)?.commissionConcepts || [];

    return {
        ContractId: ContractId,
        Contract: state.Contract?.list?.find(c => c.id === ContractId),
        ContractConcepts: state.Contract?.list?.find(c => c.id === ContractId)?.concepts?.map((concept: ContractConcept) => {
            
            const isConceptSelected = ContractCommissionConcepts.find((internalConcept: ContractCommissionConcept) => 
                internalConcept.conceptId === concept.id
            )? true : false;
            
            return { ...concept, isSelected: isConceptSelected, isUnselected: false }; 
        }),
        Practitioner: ContractPractitionerAssociated ? state.Practitioner?.list?.find((practitioner: Practitioner) => practitioner.userId === ContractPractitionerAssociated) : undefined,
        ProtocolId: ContractProtocolAssociated,
        Protocol: ContractProtocolAssociated ? state.MedicalProtocols?.list?.find((protocol: Protocol) => protocol.id === ContractProtocolAssociated) : undefined,
        feeDivision: state.Contract?.list?.find((contract: Contract) => contract.id === ContractId)?.feeDivision,
        ContractCommissionConcepts: state.Contract?.list?.find((contract: Contract) => contract.id === ContractId)?.commissionConcepts || [],
        Error: state.Contract?.error,
        DefaultContractAddendum: state.Contract?.list?.find(c => c.id === ContractId)?.addendums?[0] || undefined : undefined
    }
};

export const mapDispatchToProps = {
    ...ContractsStore.actionCreators,
    ...ProtocolsStore.actionCreators,
    ...PractitionersStore.actionCreators,
    ...ContractCommissionConceptStore.actionCreators,
    ...ContractConceptStore.actionCreators,
    ...ContractStageStore.actionCreators,
    ...ContractAddendumStore.actionCreators
};

//#endregion Props