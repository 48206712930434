import { reducer as oidcReducer } from 'redux-oidc';

import * as medicalInsurances from '../reducers/MedicalInsuranceReducer';
import * as medicalInsurancePlans from '../reducers/MedicalInsurancePlansReducer';
import * as medicalServices from '../reducers/MedicalServicesReducer';
import * as medicalInsuranceAgreements from '../reducers/MedicalInsuranceAgreementsReducer';
import * as configuration from '../reducers/ConfigurationReducer';
import * as priceDetails from '../reducers/PriceListDetailsReducer';
import * as priceList from '../reducers/PriceListReducer';
import * as practitionerContracts from '../reducers/PractitionerContractReducer';
import * as tenantPriceLists from '../reducers/TenantPriceListReducer';
import * as agreementServices from '../reducers/AgreementServicesReducer';
import * as Practitioner from '../reducers/PractitionerReducer';
import * as Provider from '../reducers/ProviderReducer';
import * as ProviderMedicalServiceAssociation from '../reducers/ProviderMedicalServiceAssociation.Reducer';
import * as ProviderPriceListAssociation from '../reducers/ProviderPriceListAssociation.Reducer';
import * as Sponsor from '../reducers/SponsorReducer';
import * as AgreementServiceDetails from '../reducers/AgreementServiceDetailsReducer';
import * as PractitionerMedicalInsuranceAssociations from '../reducers/PractitionerMedicalInsuranceAssociation.Reducer';
import * as PractitionerMedicalServiceAssociations from '../reducers/PractitionerMedicalserviceAssociation.Reducer';
import * as PractitionerSettingsReducer from './PractitionerSettings.Reducer';
import * as ProfessionalAvailabilityReducer from './ProfessionalAvailabilityReducer';
import * as MedicalProtocolReducer from './MedicalProtocolsReducer';
import * as ContractReducer from './ContractReducer';
import * as ContractAddendumReducer from './ContractAddendums.Reducer';
import * as ContractConceptReducer from './ContractConceptReducer';
import * as ContractConceptConceptReducer from './ContractConceptConceptReducer';
import * as ContractConceptPaymentScheduleReducer from './ContractConceptPaymentScheduleReducer';
import * as ContractStageReducer from './ContractStageReducer';
import * as ContractBranchReducer from './ContractBranchReducer';
import * as ContractStageCycleReducer from './ContractStageCyclesReducer';
import * as SettlementReducer from './SettlementReducer';
import * as SettlementDetailsReducer from './SettlementDetailReducer'; 
import * as SettlementResumeReducer from './SettlementResumeReducer';
import * as ProtocolPractitionerReducer from './ProtocolPractitionerReducer';
import * as LanguageReducer from './LanguajeReducer';
import * as UserSettingsReducer from './UserSettingsReducer';
import * as ProceduresReducer from './ProceduresReducer';
import * as ContractCommissionConceptReducer from './ContractCommissionConceptReducer';
import * as ContractCommissionAgentReducer from './ContractCommissionAgent.Reducer';
import * as PractitionerProtocolVisitsReducer from './PractitionerProtocolVisitsReducer';
import * as ContractCommissionAgentConceptReducer from './ContractCommissionAgentConceptReducer';
import * as CashFlowPaymentsReducer from './CashFlow-Payments.Reducer';
import * as CashFlowAccountsReducer from './CashFlow-Accounts.Reducer';
import * as CashFlowAccountMovementsReducer from './CashFlow-AccountMovements.Reducer';
import * as CashFlowDebtsReducer from './CashFlow-Debt.Reducer';
import * as CashFlowDebtMovementsReducer from './CashFlow-DebtMovements.Reducer';
import * as PersonsReducer from './PersonReducer';

// states
import * as medicalInsuranceState from '../states/MedicalInsuranceState';
import * as medicalInsurancePlansState from '../states/MedicalInsurancePlanState';
import * as medicalInsuranceAgreementsState from '../states/MedicalInsuranceAgreementState';
import * as medicalServicesState from '../states/MedicalServicesState';
import * as configurationState from '../states/ConfigurationState';
import * as priceDetailsState from '../states/PriceListDetailsState';
import * as priceListState from '../states/PriceListState';
import * as practitionerContractsState from '../states/PractitionerContractState';
import * as tenantPriceListsState from '../states/TenantPriceListState';
import * as agreementServiceState from '../states/AgreementServicesState';
import * as PractitionerState from '../states/PractitionerState';
import * as ProviderState from '../states/ProviderState';
import * as ProviderMedicalServiceAssociationState from '../states/ProviderMedicalServiceState';
import * as ProviderPriceListAssociationState from '../states/ProviderPriceListState';
import * as SponsorState from '../states/SponsorState';
import * as AgreementServiceDetailsState from '../states/AgreementServiceDetailsState';
import * as PractitionerMedicalInsuranceAssociationsState from '../states/PractitionerMedicalInsuranceState';
import * as PractitionerMedicalServiceAssociationsState from '../states/PractitionerMedicalServiceState';
import * as PractitinerSettingsState from '../states/PractitionerSettingsState';
import * as ProfessionalAvailabilityState from '../states/ProfessionalAvailabilityState';
import * as MedicalProtocolState from '../states/MedicalProtocolsState';
import * as ContractState from '../states/ContractState';
import * as ContractAddendumState from '../states/ContractAddendum.State';
import * as ContractConceptState from '../states/ContractConceptState';
import * as ContractConceptConceptState from '../states/ContractConceptConceptState';
import * as ContractConceptPaymentScheduleState from '../states/ContractConceptPaymentScheduleState';
import * as ContractStageState from '../states/ContractStageState';
import * as ContractBranchState from '../states/ContractBranchState';
import * as ContractStageCycleState from '../states/ContractStageCycleState';
import * as SettlementState from '../states/SettlementState';
import * as SettlementDetailsState from '../states/SettlementDetailState';
import * as SettlementResumeState from '../states/SettlementResumeState';
import * as ProtocolPractitionerState from '../states/ProtocolPractitionersState';
import * as LanguajeState from '../states/LanguajeStates';
import * as UserState from '../states/UserState';
import * as AuthState from '../states/AuthUserState';
import * as ProceduresState from '../states/ProceduresState';
import * as ContractCommissionConceptState from '../states/ContractCommissionConceptState';
import * as ContractCommissionAgentState from '../states/ContractCommissionAgentState';
import * as PractitionerProtocolVisitsState from '../states/PractitionerProtocolVisitsState';
import * as ContractCommissionAgentConceptState from '../states/ContractCommissionAgentConceptState';
import * as CashFlowPaymentsState from '../states/CashFlow.Payment.State';
import * as CashFlowAccountsState from '../states/CashFlow.Account.State';
import * as CashFlowAccountMovementsState from '../states/CashFlow.AccountMovement.State';
import * as CashFlowDebtsState from '../states/CashFlow.Debt.State';
import * as CashFlowDebtMovementsState from '../states/CashFlow.DebtMovement.State';
import * as PersonState from '../states/PersonState';

export interface AppState {
  readonly MedicalInsurances:
    | medicalInsuranceState.MedicalInsuranceState
    | undefined;
  readonly MedicalInsurancePlans:
    | medicalInsurancePlansState.MedicalInsurancePlanState
    | undefined;
  readonly MedicalInsuranceAgreements:
    | medicalInsuranceAgreementsState.MedicalInsuranceAgreementsState
    | undefined;
  readonly MedicalServices:
    | medicalServicesState.AvailableMedicalServiceState
    | undefined;
  readonly Configuration: configurationState.ConfigurationState | undefined;
  readonly PriceDetails: priceDetailsState.PriceListDetailsState | undefined;
  readonly PriceList: priceListState.PriceListState | undefined;
  readonly PractitionerContracts:
    | practitionerContractsState.PractitionerContractState
    | undefined;
  readonly TenantPriceLists:
    | tenantPriceListsState.TenantPriceListState
    | undefined;
  readonly AgreementServices:
    | agreementServiceState.AgreementServicesState
    | undefined;
  readonly Practitioner: PractitionerState.PractitionerState | undefined;
  readonly Provider: ProviderState.ProviderState | undefined;
  readonly ProviderMedicalServiceAssociations: ProviderMedicalServiceAssociationState.ProviderMedicalServiceState;
  readonly ProviderPriceListAssociations: ProviderPriceListAssociationState.ProviderPriceListState;
  readonly Sponsor: SponsorState.SponsorState | undefined;
  readonly AgreementServiceDetails:
    | AgreementServiceDetailsState.AgreementServiceDetailsState
    | undefined;
  readonly PractitionerMedicalInsuranceAssociations:
    | PractitionerMedicalInsuranceAssociationsState.PractitionerMedicalInsuranceState
    | undefined;
  readonly PractitionerMedicalServiceAssociations:
    | PractitionerMedicalServiceAssociationsState.PractitionerMedicalServiceState
    | undefined;
  readonly PractitionerSettings:
    | PractitinerSettingsState.PractitionerSettingsState
    | undefined;
  readonly ProfessionalAvailability:
    | ProfessionalAvailabilityState.ProfessionalAvailabilityState
    | undefined;
  readonly MedicalProtocols:
    | MedicalProtocolState.MedicalProtocolsState
    | undefined;
  readonly Contract: ContractState.ContractState | undefined;
  readonly ContractAddendum: ContractAddendumState.ContractAddendumState | undefined;
  readonly ContractConcept: ContractConceptState.ContractConceptState | undefined;
  readonly ContractConceptConcept: ContractConceptConceptState.ContractConceptConceptState | undefined;
  readonly ContractConceptPaymentSchedule: ContractConceptPaymentScheduleState.ContractConceptPaymentScheduleState | undefined;
  readonly ContractStage: ContractStageState.ContractStageState | undefined;
  readonly ContractBranch: ContractBranchState.ContractBranchState | undefined;
  readonly ContractStageCycle: ContractStageCycleState.ContractStageCycleState | undefined;
  readonly Settlement: SettlementState.SettlementState | undefined;
  readonly SettlementDetails: SettlementDetailsState.SettlementDetailState | undefined;
  readonly SettlementResume: SettlementResumeState.SettlementResumeState | undefined;
  readonly ProtocolPractitioners: ProtocolPractitionerState.ProtocolPractitionersState | undefined;
  readonly Language: LanguajeState.LanguajeState | undefined;
  readonly UserSettings: UserState.CurrentUserState | undefined;
  readonly oidc: AuthState.AuthUserState | undefined;
  readonly Procedures: ProceduresState.ProceduresState | undefined;
  readonly ContractCommissionConcept: ContractCommissionConceptState.ContractCommissionConceptsState | undefined;
  readonly ContractCommissionAgent: ContractCommissionAgentState.ContractCommissionAgentState | undefined;
  readonly PractitionerProtocolVisits: PractitionerProtocolVisitsState.PractitionerProtocolVisitsState | undefined;
  readonly ContractCommissionAgentConcept: ContractCommissionAgentConceptState.ContractCommissionAgentConceptState | undefined;
  readonly CashFlowPayments: CashFlowPaymentsState.CashFlowPaymentState | undefined;
  readonly CashFlowAccounts: CashFlowAccountsState.CashFlowAccountState | undefined;
  readonly CashFlowAccountMovements: CashFlowAccountMovementsState.CashFlowAccountMovementState | undefined;
  readonly CashFlowDebts: CashFlowDebtsState.CashFlowDebtState | undefined;
  readonly CashFlowDebtMovements: CashFlowDebtMovementsState.CashflowDebtMovementState | undefined;
  readonly Persons: PersonState.PersonState | undefined;
}

export const reducers = {
  MedicalInsurances: medicalInsurances.MedicalInsuranceReducer,
  MedicalInsurancePlans: medicalInsurancePlans.MedicalInsurancePlansReducer,
  MedicalServices: medicalServices.MedicalServicesReducer,
  MedicalInsuranceAgreements: medicalInsuranceAgreements.MedicalInsuranceAgreementsReducer,
  Configuration: configuration.ConfigurationReducer,
  PriceList: priceList.PriceListDetailsReducer,
  PriceDetails: priceDetails.PriceListDetailsReducer,
  PractitionerContracts: practitionerContracts.PractitionerContractReducer,
  TenantPriceLists: tenantPriceLists.TenantPriceListReducer,
  AgreementServices: agreementServices.AgreementServiceReducer,
  Practitioner: Practitioner.PractitionerReducer,
  Provider: Provider.ProviderReducer,
  ProviderMedicalServiceAssociations:
    ProviderMedicalServiceAssociation.ProviderMedicalServiceAssociationReducer,
  ProviderPriceListAssociations:
    ProviderPriceListAssociation.ProviderPriceListAssociationReducer,
  Sponsor: Sponsor.SponsorReducer,
  AgreementServiceDetails:
    AgreementServiceDetails.AgreementServiceDetailsReducer,
  PractitionerMedicalInsuranceAssociations:
    PractitionerMedicalInsuranceAssociations.PractitionerMedicalInsuranceAssociationReducer,
  PractitionerMedicalServiceAssociations:
    PractitionerMedicalServiceAssociations.PractitionerMedicalServiceAssociationReducer,
  PractitionerSettings: PractitionerSettingsReducer.PractitionerSettingsReducer,
  ProfessionalAvailability:
    ProfessionalAvailabilityReducer.ProfessionalAvailabilityReducer,
  MedicalProtocols: MedicalProtocolReducer.MedicalProtocolsReducer,
  Contract: ContractReducer.ContractReducer,
  ContractAddendum: ContractAddendumReducer.ContractAddendumReducer,
  ContractConcept: ContractConceptReducer.ContractConceptReducer,
  ContractConceptConcept: ContractConceptConceptReducer.ContractConceptConceptReducer,
  ContractConceptPaymentSchedule: ContractConceptPaymentScheduleReducer.ContractConceptPaymentScheduleReducer,
  ContractStage: ContractStageReducer.ContractStageReducer,
  ContractBranch: ContractBranchReducer.ContractBranchReducer,
  ContractStageCycle: ContractStageCycleReducer.ContractStageCycleReducer,
  Settlement: SettlementReducer.SettlementReducer,
  SettlementDetails: SettlementDetailsReducer.SettlementDetailsReducer,
  SettlementResume: SettlementResumeReducer.SettlementResumeReducer,
  ProtocolPractitioners: ProtocolPractitionerReducer.ProtocolPractitionersReducer,
  Language: LanguageReducer.LanguajeReducer,
  UserSettings: UserSettingsReducer.UserSettingsReducer,
  oidc: oidcReducer,
  Procedures: ProceduresReducer.ProceduresReducer,
  ContractCommissionConcept: ContractCommissionConceptReducer.ContractCommissionConceptReducer,
  ContractCommissionAgent: ContractCommissionAgentReducer.ContractCommissionAgentReducer,
  PractitionerProtocolVisits: PractitionerProtocolVisitsReducer.PractitionerProtocolVisitsReducer,
  ContractCommissionAgentConcept: ContractCommissionAgentConceptReducer.ContractCommissionAgentConceptReducer,
  CashFlowPayments: CashFlowPaymentsReducer.CashFlowPaymentsReducer,
  CashFlowAccounts: CashFlowAccountsReducer.CashFlowAccountsReducer,
  CashFlowAccountMovements: CashFlowAccountMovementsReducer.CashFlowAccountMovementsReducer,
  CashFlowDebts: CashFlowDebtsReducer.CashFlowDebtsReducer,
  CashFlowDebtMovements: CashFlowDebtMovementsReducer.CashFlowDebtMovementsReducer,
  Persons: PersonsReducer.PersonsReducer,
};

export interface AppThunkAction<TAction> {
  (dispatch: (action: TAction) => void, getState: () => AppState): void;
}
