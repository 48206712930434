import { IIconProps } from "@fluentui/react";
import { ErrorType } from "../../../models/HttpError";
import { Settlement } from "../../../models/Entities/Settlements/Settlement";
import { SettlementDetail } from "../../../models/Entities/SettlementDetails/SettlementDetail";

// stores
import * as SettlementStore from '../../../redux/middleware/SettlementMiddleware';
import * as SettlementDetailStore from '../../../redux/middleware/SettlementDetailsMiddleware';

//State
import { AppState } from "../../../redux/reducers";

interface IProps {
    SettlementId: number;
    SettlementDetailId: number;
}
  
interface ConnectedProps {
isLoading: boolean;
isUpdating: boolean;
isUpdatedSuccessfully: boolean;
isLoadingSuccessfully: boolean;
failOnUpdating: boolean;
failOnLoading: boolean;
error: ErrorType | undefined;
Settlement: Settlement | undefined;
CurrentDetail: SettlementDetail | undefined;
AditionalValue: number;
}

interface DispatchProps {
GetSettlementDetailById: typeof SettlementDetailStore.actionCreators.GetSettlementDetailById;
ChangeExcludeStatus: typeof SettlementDetailStore.actionCreators.ChangeExcludeStatus;
MarkOk: typeof SettlementDetailStore.actionCreators.MarkOk;
}

export type Props = IProps & ConnectedProps & DispatchProps;

export const mapStateToProps = (state: AppState, ownProps: IProps) => {

   const CurrentDetail = ownProps.SettlementDetailId ? 
   ownProps.SettlementId && state.Settlement && state.Settlement.list ? state.Settlement.list.find((item: Settlement) => item.id === ownProps.SettlementId )?.details?.find((details: SettlementDetail) => details.id === ownProps.SettlementDetailId) : undefined 
   : 
   undefined;
   return {
        isLoading: state.SettlementDetails?.isLoadingAll,
        isUpdating: state.SettlementDetails?.isLoadingAll,
        isUpdatedSuccessfully: state.SettlementDetails?.successLoadingAll,
        isLoadingSuccessfully: state.SettlementDetails?.successLoadingAll,
        failOnUpdating: state.SettlementDetails?.failOnLoadingAll,
        failOnLoading: state.SettlementDetails?.failOnLoadingAll,
        isDeleting: state.SettlementDetails?.isDeletingOne,
        isDeletedSuccessfully: state.SettlementDetails?.successDeletingOne,
        failOnDeleting: state.SettlementDetails?.failOnDeletingOne,
        error: state.SettlementDetails?.error,
        Settlement: ownProps.SettlementId && state.Settlement && state.Settlement.list ? state.Settlement.list.find((item: Settlement) => item.id === ownProps.SettlementId ) : undefined,
        CurrentDetail: CurrentDetail,
        // sum of 
        AditionalValue: CurrentDetail ? CurrentDetail?.conceptValue?.reduce((acc, item) => acc + item.value, 0) : 0
    }
};

export const mapDispatchToProps = {
...SettlementStore.actionCreators,
...SettlementDetailStore.actionCreators
};


//#region Icons
export const PropertiesIcon: IIconProps = { iconName: 'DietPlanNotebook' };
export const AddIcon: IIconProps = { iconName: 'CirclePlus' };
export const EditIcon: IIconProps = { iconName: 'Edit' };
export const Exclude: IIconProps = { iconName: 'Share' };
export const Include: IIconProps = { iconName: 'Installation' };
export const ViewIcon: IIconProps = { iconName: 'View' };
export const VerifycationOk: IIconProps = { iconName: 'CheckMark' };
export const LinkContractIcon: IIconProps = { iconName: 'Link' };
export const ItemObservedIcon: IIconProps = { iconName: 'SkypeMessage' };


// functions
export const GetStatusClass = (detail?: SettlementDetail): string => {
    if (detail?.isExcluded) {
        return 'red';
    }
    if (detail?.markOk) {
        return 'green';
    }
    if (detail?.markForReview) {
        return 'yellow';
    }
    if((!detail?.isExcluded && !detail?.markOk) && detail?.haveWarning){
        return 'warning';
    }
    return '';
}; 

export const GetStatusText = (detail?: SettlementDetail): string => {
    if (detail?.isExcluded) {
        return 'settlementDetailsPage.Status.excluded';
    }
    if (detail?.markOk) {
        return 'settlementDetailsPage.Status.verified';
    }
    if (detail?.markForReview) {
        return 'settlementDetailsPage.Status.review';
    }
    return '';
};